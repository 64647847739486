.App {
  text-align: center;
}

#root{
  display: flex;
  flex-direction: column;
}

.boxed{
  max-width: 75vw;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header-menu-rows .navbar-collapse{
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.header-menu-rows .first-row-header{
  flex-direction: row;
  width: 100%;
  justify-content: end;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.link-first-row{
  display: inline-flex;
  color: black !important;
  font-size: 13px;
  padding: 5px 10px;
  font-weight: 500;
}

.link-second-row{
  display: flex;
  min-width: max-content;
  color: black;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 400;
}

.icon-footer{
  display: inline-flex;
  padding: 5px 10px;
  color: #F14E95 !important; 
  font-size: 1.1em;
}

.link-footer-first , .link-footer-second{
  font-size: 0.85em;
  font-weight: 500;
  color: #F14E95;
  display: inline-flex;
}

.link-footer-first{
  padding: 5px 15px;
}

.direction-column{
  flex-direction: column;
}

.swiper-home{
  max-height: 650px !important;
}
.swiper-content img{
  min-width: 100vw;
  height: auto;
}

.swiper-home .swiper-button-next, .swiper-home .swiper-button-prev{
  color: rgb(241, 46, 149) !important;
}

.logoheader{
  max-width: 85%;
  float: right;
  padding-right: 20%;
  top: 10px;
  position: relative;
}

.list-events{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(100% / 5 - 20px), 1fr));
  row-gap: 10px;
  column-gap: 30px;
  list-style-position: inside;
}
.list-events li, .list-events-2 li{
  border-bottom: 1px solid lightgray;
  font-size: 11px;
}

.list-events li a , .list-events-2 li a{
  color: black !important;
  font-size: 11px;
  position: relative;
  left: -10px;
  margin: 0px 5px;
}

.list-events-2 li{
  margin: 10px 5px;
}

.list-events li a:hover , .list-events-2 li a:hover{
  color: #F14E95 !important;
  font-weight: 600;
}

.list-events-2{
  list-style-position: inside;
}

.text-mejora{
  padding-left: 1.6em;
  padding-top: 1em;
  font-size: 18px;
  color: #F14E95;
  font-weight: 500;
}

.ubica{
  padding-top: 3em;
}

p.text-ubica{
  font-size: 36px;
  color: #F14E95;
  font-weight: 400;
}

#delegacion-select{
  width: 100%;
  background-color: white;
  font-size: 16px;
  border: 1px solid lightgray !important;
  padding: 15px 10px;
  border-radius: 10px;
}

.contacto{
  padding-top: 3em;
  padding-bottom: 10em;
}

.contacto p.title{
  font-size: 40px;
  color: #F14E95;
  text-align: center;
  width: 100%;
  font-weight: bold;
}

.contacto p.siguenos{
  font-size: 18px;
  line-height: 1.5;
  color: #828282;
  text-align: center;
  width: 100%;
}

.contacto .title-info{
  color: #F14E95;
  font-weight: 400;
  font-size: 24px;
  width: 100%;
  text-align: left;
}

.contacto .info-contact a{
  display: block;
  color: #F14E95;
  width: 100%;
  font-size: 16px;
  padding-bottom: 30px;
}

.contacto .info-contact a:hover{
  color: #F14E95;
  
}

.send-button{
  background-color: #F14E95;
  color: white !important;
  border: #F14E95 1px solid;
  max-width: 30%;
  margin-left: calc(var(--bs-gutter-x) * .5);
}

.swiper-content-provider div{
  min-height: 400px;
  background-color: #282c34;
  width: 100%;
}

.swiper-content-providers  img{
  width: 100%;
  height: 130px;
  border-radius: 15px;
  object-fit: cover;

}

.swiper-content-providers .title{
  display: flex;
  width: 100%;
  font-size: 1.12rem;
  font-weight: 500;
  margin-top: 5px;
  color: black;
}

.swiper-content-providers .servicios{
  display: flex;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 5px;
  color: #6c6c6c;
}

.swiper-content-providers .view-more{
    width: 100%;
    display: flex;
    color: white;
    border-radius: 10px;
    justify-content: center;
    padding: 5px 10px;
    margin-top: 10px;
    background-color: #F14E95;
    border-color: #F14E95;
}

.swiper-content-providers .view-more:hover{
  background-color: #F14E95;
}

.swiper-content-providers .from{
  display: flex;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 5px;
  color: #6c6c6c;
}

.heading-providers{
  font-size: 2rem;
  color: black;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1em;
}

.providers-grid{
  row-gap: 25px;
  margin-top: 1.8rem;
}

.swiper-home-providers .swiper-button-prev, .swiper-home-providers .swiper-button-next{
  background-color: white;
  border-radius: 50%;
  padding: 20px 22px;
}

.swiper-home-providers .swiper-button-prev::after, .swiper-home-providers .swiper-button-next::after{
  font-size: 20px;
  color: black;
}

.swiper-providers-container{
  margin-top: 30px;
}

.swiper-content-provider img{
  width: 100%;
  height: auto;
  object-fit: cover;
}

.swiper-content-provider{
  max-height: 400px;
}

.swiper-provider .swiper-button-prev::after, .swiper-provider .swiper-button-next::after{
  font-size: 40px;
  color: #F14E95;
}

.detail-provider{
  row-gap: 20px;
}

.detail-provider .title{
  font-size: 2.3em;
  color: #F14E95;
  font-weight: 500;
}

.detail-provider .servicios{
  font-size: 1.4em;
  color: #6c6c6c;
}

.detail-provider .from{
  font-size: 1.35em;
  box-shadow: 0 2px 8px #00000026;
  padding: 15px 20px;
  border-radius: 0.5rem
}

.image-provider{
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.second-row-header > div{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}


.torak-dropdown-menu{
  display: none;
  position: absolute;
  background-color: #E6E6E6;
  padding: 0.6em 0.55em;
  z-index: 999;
}

.link-second-row:hover ~ .torak-dropdown-menu , .torak-dropdown-menu:hover{
  display: flex;
  flex-direction: column;
}

.link-second-row:hover ~ .torak-dropdown-menu:empty{
  display: none;
}

.torak-dropdown-menu a {
  display: flex;
  color: black;
  font-size: 0.7em;
  padding: 0 0.5em;
  font-weight: 500;
}

.marker {
  color: red;
}



.text-marker{
  color: black;
  font-size: 14px;
  text-shadow: #00000026;
  font-weight: 600;
  margin-left: -3em;
  min-width: 100px;
  text-align: center;
}

.website , .website a , .phone, .phone a{
  color: #F14E95;
}


.swiper-providers{
  max-height: 480px;
}

.swiper-providers img{
  width: 100%;
  object-fit: cover;
}


.info p{
  font-weight: 500;
  padding: 1em 0;
}

.swiper-providers .swiper-button-next, .swiper-providers .swiper-button-prev{
  color: #F14E95;
}

.container {
  padding-bottom: 3.5em;
}

.from.services.col {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
}

.image-ico-provider {
  max-height: 30px;
}

.heading-row{
  position: relative;
}

.filterButton{
  position: absolute;
  width: fit-content;
  top: 65%;
}

.filterButton span{
  cursor: pointer;
  color: #F14E95;
}

/* Estilos para Popup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 500px;
}

.close-button {
  background: #F14E95;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.scrollable-input{
  max-height: 100%;
}

.inputGroup{
  display: flex;
  column-gap: 1em;
}

.inputGroup * {
  display: inline-flex;

}

.inputGroup svg{
  margin-left: 5px;
  margin-top: 3px;
  color: gold;
}

.filterTitle{
  color: #F14E95;
  font-weight: 500;
  padding: 10px;
}

.sendFilters{
  background-color: #F14E95;
  border: 1px solid #F14E95;
  font-size: 14px; 
}
.sendFilters:hover{
  background-color: #f14e95ca;
  border: 1px solid #F14E95;
}

.inputCol{
  display: flex;
  flex-direction: row;
}

.inputCol .inputGroupCol input{
  border: 1px solid #F14E95;
  border-radius: 5px;
  padding: 3px 5px;
}

.inputGroup input[type='radio']:checked:after{
    background-color: #F14E95;
}

.searchForm {
  display: flex;
  justify-content: center;
  width: 100%;
}

.searchForm input{
  display: flex;
  width: 50%;
  border: 1px solid #F14E95;
  padding: 5px 5px;
  border-radius: 10px 0px 0px 10px;
}

.button-search {
  display: flex;
  background-color: #F14E95;
  color: white;
  border: 1px solid #F14E95;
  border-radius: 0px 10px 10px 0px;
  padding: 8px 15px 10px 10px;
}

.not-results div{
  color: #F14E95;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.filters-rows{
  max-height: 100%;
  overflow-y: scroll;
}

.terms_conditions{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
  column-gap: 0.1em;
}
.spinner-not-active{
  display: none;
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled{
  background-color: #c9c9ca !important;
  color: #555454 !important;
}

button.nav-link{
  background-color: #f683b5 !important;
  color: white !important;
}

.nav-tabs .nav-link.active{
  color: white !important;
  background-color: #F14E95 !important;
  font-weight: 700;
}

.card-plan{
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  padding: 10px;
  align-items: center;
  border: 1px solid #f14e9563;
  transition: 0.45s ease-in-out background-color ;
}

.plan-title{
  font-size: 1.8em;
  color: #F14E95;
  text-align: center;
  font-weight: 600;
}

.plan-price{
  font-size: 1em;
  font-weight: 600;
}

.plan-especs{
  font-size: 0.8em;
  text-align: left;
  height: 100%;
}

@media only screen and (min-width: 1024px) and (max-width: 1480px){
  .header-min{
    max-width: 90vw !important;
  }

  .navbar:has(.header-min){
    justify-content: center;
  }
}

.wishlist-button{
  display: flex;
  justify-content: center;
  margin-top: 1em ;
  align-items: center;
}

.addWishList{
  background-color: #F14E95;
  border: 1px solid #F14E95;
}

.addWishList:hover{
  background-color: #f14e95bd;
  border: 1px solid #F14E95;
}

.item-provider-wish{
  margin: 0.5em 0;
}

.item-provider-wish div{
  display: flex;
}

.item-provider-wish .col a, .item-provider-wish .col span{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.item-provider-wish .col:has(.name-wish) a{
  justify-content: start;
}

.item-button{
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.item-button .btn{
  background-color: #F14E95;
  border: 1px solid #F14E95;
}


.heading-wish{
  text-align: center;
  font-size: 2em;
  color: #F14E95;
  font-weight: 600;
}

.name-wish{
  color: #F14E95;
  font-weight: 600;
}

.no-results-prov{
  text-align: center;
  font-size: 1.5em;
  padding: 30vh 0;
}

.wish-link{
  color: #F14E95;
  padding: 0 1em;
}

.sendCotizacion{
  padding: 2em 0;
}
.sendCotizacion div{
  display: flex;
  justify-content: end;
}

button , a.button-editAccount{
  display: flex;
  background-color: #F14E95 !important;
  border: 1px solid #F14E95 !important;
}
button:hover{
  background-color: #f14e95bd !important;
  border: 1px solid #F14E95 !important;
}

.heading-providers-promo{
  font-size: 2.2rem;
  color: #F14E95;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1em;
}

.description-providers-promo{
  font-size: 1.8em;
  color: black;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0.2em;
}

.progressSteps{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.notshow{
  display: none;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  background-color: #F14E95 !important;
  color: white !important;
}

.nav-pills .nav-link{
  color: #F14E95 !important;
}

.uploadFile form{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
}

.uploadFile .col{
  display: flex;
  justify-content: center;
}

.uploadFile .col span{
  font-size: 1.3em;
  color: #F14E95;
  font-weight: 500;
  padding: 0.4em 0;
}
.payment-container .row{
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
}
.payment-container a{
  color: #F14E95;
}
.latest-pay{
 font-size: 1em; 
}

.status-pay.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment-status {
  padding: 7px 14px;
  border-radius: 5px;
}

.payment-status.declined{
  color: white;
  background-color: orangered;
}

.payment-status.verified{
  color: white;
  background-color: green;
}

.payment-status.process{
  color: white;
  background-color: orange;
}

.info-multimedia span{
  color: #282c34;
  font-size: 18px;
  font-weight: 600;
}

.loader-torak-child{
  display: flex;
  position: absolute;
  width: 100%;
  flex-direction: column;
  color: #F14E95;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.7);
}

.media-video{
  object-fit: cover;
  height: 190px;
}

.card-plan.selected{
  background-color: #f14e95a3;
}

.card-plan.selected .plan-title{
  text-shadow: -1px 1px 8px #FFFFFF;
}

.card-plan.selected .plan-price , .card-plan.selected .plan-especs{
  color: rgba(255, 255, 255, 0.747);
}

.button-editAccount{
  font-size: 13px;
  padding: 5px 10px;
  margin: 0;
  height: fit-content;
}

@media screen and (max-width: 720px) {
  .boxed{
    max-width: 99vw;
  }

  .swiper-home-providers .swiper-button-prev::after, .swiper-home-providers .swiper-button-next::after{
    color: #F14E95;
  }

  .list-events{
    grid-template-columns:  auto;
    padding:  0 4%;
  }

  .list-events li{
    font-size: 15px;
  }
  .contacto p.title-info, .contacto .info-contact{
    text-align: center !important;
  }

  .button-form{
    justify-content: center;
  }

  .header-min{
    padding: 0;
    width: 100%;
  }

  .top-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .toggle-button-header{
    margin-top: 20px;
  }

  .second-row-header .col-lg-12{
    flex-direction: column;
  }
  .header-menu-rows .first-row-header{
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
    text-align: center;
    justify-content: center;
  }

  .torak-dropdown-menu{
    width: calc(100vw - 40px);
    left: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size:18px;
  }

  .swiper-content-providers a div{
      max-height: 110px !important;
  }

  .heading-providers{
    text-align: center;
  }

  .filterButton{
    top: 95%
  }

  .wishlist-button{
    flex-direction: column;
  }

  .title{
    text-align: center;
  }
  .from{
    text-align: center;
  }
  .from.services{
    justify-content: center;
  }

  .website, .phone{
    justify-content: center;
    text-align: center;
  }
  .item-provider-wish.first-table{
    display: none;
  }

  .only-mobile{
    display: flex !important;
    width: 100%;
  }

  hr.only-mobile{
    color: #F14E95;
    height: 3px;
  }

  .label-hf{
    text-align: left !important;
    margin-left: 5px;
  }

  .other-pay .col span, .latest-pay .col span{
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
  }

  .other-pay .col a, .latest-pay .col a{
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  h2{
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filterButton{
    position: relative;
    padding: 15px 15px;
  }

}

.only-mobile{
  display: none;
}

.showPass{
  position: absolute;
  top: 20%;
  right: 8%;
}

@media screen and (min-width: 1900px) {
  .header-min .row{
    width: 100%;
    max-width: 1500px;
  }
}

.toast-payment{
  width:100%;
}


.latest-pay{
  position: relative;
}

.notification-pay{
  position: absolute;
  background-color: transparent !important;
  border: none !important;
  color: gold;
  right: 10px;
  font-size: 28px;
}

.notification-pay.gray{
  color: gray;
}

.title-container{
  display: flex;
  justify-content: start;
  text-align: left;
  font-size: 25px;
  color: #F14E95;
  font-weight: 600;
}

.plan-container{
  display: flex;
  justify-content: right;
  align-items: center;
  text-align: left;
}

.plan-description-header{
  color: #F14E95;
  font-size: 18px;
  font-weight: 500;
}

.containerChangeplan {
  display: flex;
  justify-content: center;
  padding: 15px 10px;
}

.containerChangeplan button{
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 20px;
}

.noti-title{
  font-size: 19px;
  font-weight: 600;
}

.noti-file , .noti-mark{
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.noti-mark button{
  background-color: transparent !important;
  border: none !important;
  font-size: 25px;
}

.notification.viewed{
  background-color: rgba(211, 211, 211, 0.731);
}

.notification{
  padding: 1em;
  border-radius: 15px;
}

.notification-container{
  display: flex;
  row-gap: 0.8em;
}

.error-data{
  color: white;
  font-size: medium;
  font-weight: 600;
  background-color: rgb(250, 73, 3);
}


.modal-information .modal-dialog{
  min-width: 70%;
}

.modal-information .modal-dialog .modal-content{
  min-height: 300px;
}

.modal-information .modal-body{
  justify-content: center;
  align-items: center;
  display: flex;
}