body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.emptyProviders{
  display: flex;
  font-size: 35px;
  color: #F14E95;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55vh;
}


.container{
  margin-top: 0.5em;
}


.About-Texts{
  padding: 0 2.5em;
}

.item-about{
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  flex-wrap: nowrap;
  padding-bottom: 1em;
}

.item-about .icons{
  display: flex;
  justify-content: center;
  width: 65px;
  max-height: 65px;
  margin-top: 30px;
  flex-wrap: wrap;
  align-items: center;
  font-size: 30px;
  color: #F14E95;
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  padding: 15px;
}

.item-about .text{
  display: flex;
  font-family: 'Work Sans', Arial, Helvetica, sans-serif;
  flex-direction: column;
  row-gap: 0.25em;
  width: 85%;
  padding: 1em 1.3em;
}

.item-about .text .heading-2{
  font-size: 18px;
  color: #F14E95;
  font-weight: 400;
}
.item-about .text .body{
  font-size: 16px;
  color: #828282;
  font-weight: 400;
}

.content-privacy{
    padding: 1.5em 2.5em 2.5em 2.5em;
}

.content-privacy .heading-1{
  font-size: 24px;
  display: flex;
  color: #F14E95;
  font-weight: 500;
  padding: 15px 10px 40px 10px;
}

.content-privacy .heading-2{
  font-size: 16px;
  display: flex;
  color: #F14E95;
  font-weight: 500;
  padding: 15px 0 5px 0;
}

.content-privacy .text{
  color: #666666;
  font-size: 14.5px;
  font-weight: 400;
  text-align: justify;
}

.pink-text{
  color: #F14E95;
  font-weight: 500;
}

#dropdown-user{
  background-color: transparent !important;
  color: #F14E95;
  font-weight: 600;
  padding: 0;
  border: none !important;
}
